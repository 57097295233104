@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap");
* {
  box-sizing: border-box;
}
body {
  margin: 0;
  /* font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; */
  font-family: "Open Sans", sans-serif !important;
  background-color: #fdfdff !important;
  /* background-color: #f7f7fd !important; */
  height: 95%;
  color: #5e5b70;
}
#root {
  height: 100%;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Body #f7f7fd */
/* //#c4bedabd */
/* #EBE9F2 */
